// Generated by Framer (015b6bb)

import { addFonts, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["xO4yH7Akj"];

const serializationHash = "framer-Ny3Md"

const variantClassNames = {xO4yH7Akj: "framer-v-12bae4j"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, width, ...props}) => { return {...props} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "xO4yH7Akj", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} background={{alt: "waveformlabs.io", fit: "fit", pixelHeight: 150, pixelWidth: 214, positionX: "center", positionY: "center", src: "https://framerusercontent.com/images/dyRdfZ1QvQw78xoKqLT5Chzgo0.svg"}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-12bae4j", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"xO4yH7Akj"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-Ny3Md.framer-1ija6c, .framer-Ny3Md .framer-1ija6c { display: block; }", ".framer-Ny3Md.framer-12bae4j { align-content: center; align-items: center; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 298px; justify-content: center; padding: 0px; position: relative; width: 464px; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-Ny3Md.framer-12bae4j { gap: 0px; } .framer-Ny3Md.framer-12bae4j > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-Ny3Md.framer-12bae4j > :first-child { margin-left: 0px; } .framer-Ny3Md.framer-12bae4j > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 298
 * @framerIntrinsicWidth 464
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerF5S7UZFsh: React.ComponentType<Props> = withCSS(Component, css, "framer-Ny3Md") as typeof Component;
export default FramerF5S7UZFsh;

FramerF5S7UZFsh.displayName = "Logo Desktop";

FramerF5S7UZFsh.defaultProps = {height: 298, width: 464};

addFonts(FramerF5S7UZFsh, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})